.footer-upper-row{
    justify-content: space-between;
    margin: 36px 16px 48px;
    display: flex;
}

.footer-lower-row{
    justify-content: space-between;
    display: flex;
}

.text-link {
    opacity: .5;
    transition: opacity .2s;
  }
  
  .text-link:hover {
    opacity: 1;
  }

  .footer-image {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    left: auto;
    right: 10px;
  }


  
  .footer-image img{
    opacity: .50;
    transition: opacity 0.3s ease;
  }
  
  .footer-image img:hover{
    opacity: 1;
  }

