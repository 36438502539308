@import url('typeface-inter');

/* @import 'typeface-inter-var'; */


/* Define font weight variants */

/* 
.inter-font {
    font-family: 'Inter', sans-serif;
  }

.normal {
    font-family: 'Inter', sans-serif;
    font-weight: 400; 
  }
  
.medium {
    font-family: 'Inter', sans-serif;
    font-weight: 500; 
  }
  
  .semibold {
    font-family: 'Inter', sans-serif;
    font-weight: 600; 
  }
  
  .bold {
    font-family: 'Inter', sans-serif;
    font-weight: 700; 
  } */


/* CSS for colors used in the project */

.black { 
    color:  #000!important;
}

.white { 
  color:  #e9e9e9!important;
}

.transparent { 
  color:  #fff0!important;
  opacity: 0;
}

/* Primary Colors */
:root {
  --flame-purple-1: #f72585;
  --flame-purple-2: #b5179e;
  --flame-purple-3: #7209b7;
  --flame-purple-4: #560bad;
  --flame-purple-5: #480ca8;
  --flame-blue-6: #3a0ca3;
  --flame-blue-7: #3f37c9;
  --flame-blue-8: #4361ee;
  --flame-blue-9: #4895ef;
  --flame-blue-10: #4cc9f0;
  --primary-1: #ff715b;
  --primary-2: #ff934f;
  --primary-3: #3ccf91;
  --primary-4: #3c5ccf;
  --primary-5: #5132c0;
  --primary-6: #8f2df0;
  --gray-1: #12141d;
  --gray-2: #252525;
  --gray-3: #fff;
  --gray-4: #fff;
}

.primary-1 {
    background-color: #ff715b!important;
}

.primary-2 {
  background-color: #ff934f!important;
}

.primary-3 {
  background-color: #3ccf91!important;
}

.primary-4 {
  background-color: #3c5ccf!important;
}

.primary-5 {
  background-color: #5132c0!important;
}

.bg-primary-6 {
  background-color: #8f2df0;
}

.gray-1 {
  background-color: #12141d!important;
}

.gray-2 {
  background-color: #252525!important;
  opacity: 5;
}

.gray-3 {
  background-color: #fff!important;
  opacity: 10;
}

.gray-4 {
  background-color: #fff!important;
  opacity: 50;
}

/* Borders */
.border-color-1 {
  border-color: #c5c5c5a2;
}

.border-color-2 {
  border-color: #292929a2;
}

.border-color-3 {
  border-color: 1px solid #e2e2e2;
}

.border-color-4 {
  border-color: 1px #383838;
}

.border-position-fixed-left{
  position: fixed;
  left: 15%;
  top: 0;
  bottom: 0;
}

.border-position-fixed-right{
  position: fixed;
  right: 15%;
  top: 0;
  bottom: 0;
}

.border-left{
  border-left: 1px solid #292929;
  height: 100vh;
}

.border-right{
  border-right: 1px solid #292929;
  height: 100vh;
}


/* Grid for page */
.vertical-border-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vertical-border-wrapper{
  position: fixed;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1300px;
  justify-content: space-between;
  top: 0;
}

.vertical-border-left{
  border-left: 1px solid #2b2b2ba2;
  height: 100vh;
}

.vertical-border-right{
  border-right: 1px solid #2b2b2ba2;
  height: 100vh;
}




/* Gradients */

.bg-gradient-7 {
  background-image: linear-gradient(to right, #ff715b, #5132c0);
}

.bg-gradient-3 {
  background-image: linear-gradient(to right, #3ccf91, #3c5ccf);
}

.bg-gradient-4 {
  background-image: linear-gradient(to right, #3c5ccf, #5132c0);
}

.bg-gradient-9 {
  background-image: linear-gradient(to right, #8f2df0, #ff934f);
}

.bg-gradient-6 {
  background-image: linear-gradient(to right, #3ccf91, #3c5ccf);
}

.bg-gradient-8 {
  background-image: linear-gradient(to right, #dd8d25, #eba53c);
}


.bg-gradient-2 {
  background-image: linear-gradient(to right, #ff934f, #3ccf91);
}

.bg-gradient-5 {
  background-image: linear-gradient(to right, #5132c0, #8f2df0);
}

.bg-gradient-6-reverse {
  background-image: linear-gradient(to right, #ff715b, #3c5ccf);
}

.hero-gradient-background {
  height: 250px;
  width: 100%;
  filter: blur(100px);
  position: relative;
  opacity: 0.6;
  top: 200px;
  z-index: -1000;
  background: linear-gradient(
    to right, 
    var(--flame-purple-1) 0%, 
    var(--flame-purple-2) 10%, 
    var(--flame-purple-3) 20%, 
    var(--flame-purple-4) 30%, 
    var(--flame-purple-5) 40%, 
    var(--flame-blue-6) 50%, 
    var(--flame-blue-7) 60%, 
    var(--flame-blue-8) 70%, 
    var(--flame-blue-9) 80%, 
    var(--flame-blue-10) 90%, 
    var(--flame-blue-10) 100%
  );
}


.hero-color-mesh{
  position: absolute;
  top: 0;
  z-index: -1000;
  width: 100%;
  height: 1000px;
  background-color:rgb(255 0 0 / 0%);
  
  /* background-image:
  radial-gradient(at 21% 43%, hsla(28,100%,74%,1) 0px, transparent 50%),
  radial-gradient(at 80% 0%, hsla(189,100%,56%,1) 0px, transparent 50%),
  radial-gradient(at 0% 50%, hsla(355,100%,93%,1) 0px, transparent 50%),
  radial-gradient(at 83% 22%, hsla(340,100%,76%,1) 0px, transparent 50%),
  radial-gradient(at 0% 100%, hsla(22,100%,77%,1) 0px, transparent 50%),
  radial-gradient(at 77% 89%, hsla(242,100%,70%,1) 0px, transparent 50%),
  radial-gradient(at 0% 0%, hsla(343,100%,76%,1) 0px, transparent 50%); */
}



/* Secondary text color */
.text-gray-4{
  color: #fff!important;
  opacity: .5;
}

/* Container CSS for various elements */
body {
  color: rgb(255 255 255 / 50%);

  /* background-color: #12141d; */
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

h1 {
  color: #f0f0f0;
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 24px;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 44px;
}

h2 {
  color: var(--text-color);
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 24px;
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

h3 {
  color: var(--text-color);
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 24px;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

h4 {
  color: var(--text-color);
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 16px;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}

h5 {
  color: var(--text-color);
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 12px;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

h6 {
  color: var(--text-color);
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 12px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}



a {
  color: var(--text-color);
  font-family: Inter, sans-serif;
  font-weight: 600;
  text-decoration: none;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 24px;
}

img {
  max-width: 100%;
  display: inline-block;
}

blockquote {
  color: var(--text-color);
  letter-spacing: -.01em;
  background-color: #8f2df0;
  border-left: 5px #e2e2e2;
  border-radius: 12px;
  margin-top: 48px;
  margin-bottom: 48px;
  padding: 48px;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

figure {
  margin-bottom: 10px;
}

figcaption {
  text-align: center;
  margin-top: 12px;
  font-size: 14px;
  line-height: 22px;
}

.underline-text{
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

.section{
  display: block;
  position: relative;
  margin-top: 144px;
  margin-bottom: 144px;
}

/* Hero content */
.hero-section{
  margin-top: -156px;
  position: relative;
}

.hero-content{
  margin-left: 25px;
}

.hero-vertical-line {
  width: 4px;
  border-radius: 5px;
  height: 100%;
  position: absolute;
  margin-right: 15px;
  background: linear-gradient(
    to bottom, 
    var(--flame-purple-1) 0%, 
    var(--flame-purple-2) 10%, 
    var(--flame-purple-3) 20%, 
    var(--flame-purple-4) 30%, 
    var(--flame-purple-5) 40%, 
    var(--flame-blue-6) 50%, 
    var(--flame-blue-7) 60%, 
    var(--flame-blue-8) 70%, 
    var(--flame-blue-9) 80%, 
    var(--flame-blue-10) 90%, 
    var(--flame-blue-10) 100%
  );
}

/*  */
.hero-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
  height: 100%;
}

.hero-panel {
  background-color: #ffffff0d;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 16px;
}

.hero-panel img {
  margin-right: 16px;
  scale: 0.8;
}

.hero-panel h6 {
  margin: 0;
  font-size: 16px;
}

.container {
  width: 100%;
  max-width: 1296px;
  grid-gap: 75px 16px;
  perspective: 500px;
  cursor: auto;

  /* grid-template-rows: auto auto;
  grid-template-columns: 2.25fr 1.25fr;
  grid-auto-columns: 1fr; */
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  display: block;
}

/*  */


.container.grid-container {
  grid-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.container-badge{
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.video-container{
  width: 100%;
  height: 100%;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

/* Directional Properties */
.extend-elements-vertical{
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  
}

.extend-elements-horizontal{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
}

/* Panels and Properties */

.panel.fixed-height-panel{
  height: 250px;
}

.panel.fixed-height-panel-image{
  height: 694px;
}

.panel {
  direction: ltr;
  background-color: #ffffff0d;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  display: block;
  overflow: hidden;
}

.panel-body {
  z-index: 1;
  width: 100%;
  height: calc(100% - 36px);
  grid-gap: 16px;
  backface-visibility: hidden;
  flex-direction: column;
  flex: 1 0 auto;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-self: auto;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  padding: 36px;
  display: flex;
  position: relative;
}

.panel-body.flex-start {
  justify-content: flex-start;
}

.panel-body.justify-items-center{
  place-items: center center;
  flex-direction: row;
}

.panel-subheading{
  display: block;
  margin-bottom: 24px;
}


/* Width elements to be used for content  */
.content-width-small{
  width:100%;
  max-width: 400px;
}

.content-width-medium{
  width:100%;
  max-width: 612px;
}

.content-width-large{
  width:100%;
  max-width: 824px;
}

.content-width-extra-large{
  width:100%;
  max-width: 964px;
}

.display-inline{
  display: inline;
}

.space-top-extra-large{
  display: block;
  margin-top: 48px;
}

.space-top-large{
  display: block;
  margin-top: 24px;
}

/* block mt-4 */
.space-top-medium{
  display: block;
  margin-top: 16px;
}

.space-top-small{
  display: block;
  margin-top: 8px;
}

.space-bottom-extra-large{
  display: block;
  margin-bottom: 48px;
}

.space-bottom-large{
  display: block;
  margin-bottom: 24px;
}

.space-bottom-medium{
  display: block;
  margin-bottom: 16px;
}

.space-bottom-small{
  display: block;
  margin-bottom: 8px;
}

.no-bottom-space {
  margin-bottom: 0;
  padding-bottom: 0;
}

.no-top-space {
  margin-top: 48px;
  padding-top: 0;
}

.para-nospace {
  margin-bottom: 0;
}

.brand-logo{
  transform: scale(.90);
  margin-right: 15px;
}

.profile-holder{
    width: 90px; /* Adjust the size as needed */
    height: 90px; /* Adjust the size as needed */
    border-radius: 50%; /* Makes the container circular */
    margin-left: 25px;
    overflow: hidden; /* Ensures the image stays within the circle */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--flame-blue-10); /* Optional: background color */
}

.profile-holder img{
  display: block;
  width: 95%;
  height: 95%;
}


.badge{
    height: 32px;
    min-width: 32px;
    color: #fff;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    box-shadow: inset 0 20px 20px 20px rgb(0 0 0 / 0%);
}

.badge-label{
  display: block;
  padding-left: 12px;
  padding-right: 12px;
}

.button-row{
  position: absolute;
  bottom: 0;
}

/* GRID SIZE CSS */
.layout{
  display: grid;
}

.layout-grid {
  grid-gap: 16px;  
}

.grid-halves {
  /* max-width: 824px; */
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  min-height: auto;
}

.grid-direction-vertical{
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.grid-direction-vertical-auto{
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;

}

.grid-direction-horizontal{
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.grid-thirds{
  display: grid;
  width: 100%;
  min-height: auto;
  grid-template-columns: repeat(3, 1fr);
  margin-left: auto;
  margin-right: auto;
}

.grid-two-thirds {
  display: grid;
  max-width: 100%;
  min-height: auto;
  grid-template-columns: 1fr 2fr;
  margin-left: auto;
  margin-right: auto;
}

/* IMAGES */
.rounded-image {
  cursor: pointer;
  border-radius: 12px;
  position: relative;
}

.slider-image{
  padding-right: 10px;
}

.rounded-image.slider-image{
  padding-right: 10px;
}

/* GRID IMAGE CSS */

/* TEST */
.grid-image-cover{
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.grid-image-container{
  max-width: 100%;
  height:694px;
  overflow: hidden;
}


.grid-image {
  border-radius: 12px;
  width: 100%;
  height: 100%;
}

.grid-image-fixed-height{
  border-radius: 12px;
  width: 100%;
  height: 694px;

}

.div-block{
  flex: 1;
}

/* Scaling factors */
.scale50{
  transform: scale(.50);
}

.scale70{
  transform: scale(.70);
}

.scale90{
  transform: scale(.90);
}

.scale120{
  transform: scale(1.2);
}

.scale150{
  transform: scale(1.5);
}


/* Button CSS to be transfered to its own file */
.slider-button{
  background-color: #000;
  border-radius: 12px;
  color: #6d6d6d;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slider-button:hover{
  background-color: #5f5f5f;
}

.slick-dots-test{
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: row;

}

/* Rounded Button */
.rounded-button {
  display: inline-block;
  background-color: #e2e2e2;
  color: #fff;
  border-radius: 40px;
  padding: 5px 8px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  border: none;
  transition: background-color 0.3s ease;
}

.rounded-button:hover {
  background-color: #9b9b9b;
}

.rounded-button.scroll-button{
  position: fixed;
  bottom: 20px;
  right: 40px;
  background-color: rgb(255 255 255 / 5%);
  z-index: 1000000;
}

.rounded-button.scroll-button:hover{
  opacity: 0.8;
}


.button {
    border: none;
    font-size: 16px;
    color: rgb(255 255 255);
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    
  }

  .button:hover {
      opacity: 0.8;
      transition: opacity 0.3s ease;
    
  }

  .button.sm {
    padding-left: 36px;
    padding-right: 36px;
    height: 30px;
  }
  
  .button.md {
    padding-left: 36px;
    padding-right: 36px;
    height: 40px;
  }
  
  .button.lg {
    padding-left: 36px;
    padding-right: 36px;
    height: 60px;
  }
  
  .button-icon{
    margin-right: 8px;
  }

/* List styles */
ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.bordered-list-item-small {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  margin-bottom: 12px;
  padding-bottom: 12px;
}

  /* Element Trasnform Properties/ Animations */
  .image-transform {
    animation: shrinkAndDisappear1 2s linear;
    will-change: opacity, transform;
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX('0deg') rotateY('0deg') rotateZ('0deg') skewX('0deg') skewY('0deg');
    transform-style: preserve-3d;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  @keyframes shrinkAndDisappear1 {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(0.5);
      opacity: 0;
    }
  }

  
  .scroll-image {
    animation: shrinkAndDisappear2 2s ease-out f;
  }

  @keyframes shrinkAndDisappear2 {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(0.5);
      opacity: 0;
    }
  }


 .modal {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: #12141d;
  opacity: 1;
  z-index: 999; 
 }

 
.modal-content {
   /* White background */
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 0 10px rgb(61 61 61); /* Shadow effect */
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.input-button-row{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 8px ;
  
}

input{
  height: 40px;
  border-radius: 6px;
  background-color: #222;
  border: #383838;
  color: white;
  padding-left: 8px;
}

.error-message {
  color: rgb(255 255 255);
  margin-top: 10px;
}

.typing-animation{
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 3.5s steps(40, end), .75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-radius: 2px;
  opacity: 0.8;
  background-image: linear-gradient(to right, #2e2e2e,#3d3d3d, #d8d8d8);
  transform-origin: 0%;
  z-index: 1000000;
}


